.topnav {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.nav-links {
    display: flex;
    justify-content: center;
    flex: 1;
}

.nav-link {
    padding: 0px 16px;
    color: #545454;
    text-decoration: none;
    font-family: var(--main-font);
    font-weight: 600;
}

.logo img {
    width: 70px;
    padding: 0;
}

.nav-logo-img {
    /* width: 70px; */
        /* height: 54px; */
}

.nav-2 {
    display: flex;
    margin-right: 8px;
}

.nav-link:hover {
    color: orange;
}

@media only screen and (max-width: 900px) {
    nav, .nav-links {
        flex-direction: column;
    }

    nav {
        align-items: flex-start;
        padding: 0;
    }

    .logo {
        padding: 8px 16px;
    }
    
}

.regular-link {
    text-decoration: none;
    color: unset;
    display: flex;
    align-items: center;
}