/* DEFINING COMMON VARIABLES */
:root {
    --primary-color: #00bfff;
    --secondary-color: #0b4f66;
    --neutral-lightest: #fff;
    --neutral-lightest_hover: #F2F2F2;
    --accent-color: #f89925;
    --accent-color_hover: #df7e07;
    --neutral-light: #c5e7f3;
    --neutral-med: rgb(138, 153, 170);
    --neutral-dark: #555;

    --main-font: 'Poppins', sans-serif;

    --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }


/* INPUT TEXT BOXES */
.form-group {
    margin-bottom: 1vh;
    justify-content: center;
}

.form {
    color: var(--neutral-lightest);
    background-color: var(--neutral-lightest);
}
.input-form {
    /* display: flex; */
    border: 1px solid var(--neutral-light);
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    width: 97%; /* why is this the only thing making the textareas working??? */
    padding: .375rem .75rem; 
    background-color: var(--neutral-lightest);
    font-family: var(--main-font);
    color: var(--neutral-med);
}

.input-no-outline, .input-no-outline:focus {
    /* display: flex; */
    border: 0px solid var(--neutral-light);
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    height: 2.5vh;
    flex: 1;
    font-family: var(--main-font);
}

.field {
    margin: 1vh;
    background: var(--neutral-lightest) !important;
    color: var(--neutral-lightest);
}

.input-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid var(--neutral-med);
        font-weight: 400;
        border-radius: 5px;
        height: 42px; /* with a 1px border on top and bottom, this makes it 45 which is a nice even number*/
        padding: 0 0; 
        background-color: var(--neutral-lightest);
        font-family: var(--main-font);
}

.search-box {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin: 1vh 0vh;
}

.search-box-center {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.input-form:focus, .field:focus{
    border: 1px solid var(--neutral-med);
    background-color: var(--neutral-lightest);
}

input:focus, textarea:focus, select:focus {
    outline-offset: 0px !important;
    outline: none !important;
}

@media only screen and (max-width: 900px) {
    .input-box, .search-box, .input-form {
        justify-content: center;
    }
    .input-box, .search-box {
        justify-content: center;
        padding: 0;
    }
    .input-form {
        height: 5vh;
        width: auto;
    }
}


/* INPUT ELEMENTS */

/* buttons */
.btn {
    align-self: center;
    margin: 5px;
    /* display:block; */
    display: flex;
    /* justify-content: center; */
	color:var(--neutral-lightest);
	padding: 5px 10px;
    text-decoration: none;
    border: 1px solid transparent;
    font: 1rem / 1.5em var(--main-font);
    border-radius: .2rem;
    cursor: pointer;
}

.btn-primary {
	background-color:var(--accent-color);
}

.btn-primary:hover {
    background-color: var(--accent-color);
}

.btn-secondary {
    padding: 7px 15px;
    background-color: var(--accent-color);
}

.btn-secondary:hover {
    background-color: var(--secondary-color);
}

.btn-card {
    border: 2px solid var(--secondary-color);
    background-color: var(--neutral-lightest);
    width: 200px;
    height: 200px;
    overflow: auto;
    border-radius: 0.5rem;
}

.btn-card:focus {
    border: 2px solid var(--secondary-color);
    background-color: var(--neutral-light);
    width: 200px;
    height: 200px;
    overflow: auto;
    border-radius: 0.5rem;
}

.btn-cards {
    display: flex;

}

.submit {
    width: 100%;
}

.link {
    text-decoration: none;
}


/* IMAGES */
.icon {
    color: var(--neutral-med);
}

/* the logo images of different companies with which we create pipelines */
.image, .image-no-hover {
    width: 90%;
    height: 90%;
    border-radius: 0.5rem;
}

.image:hover {
    opacity: 0.3;
}

.image-no-hover:hover {
    opacity: 100%;
    width: inherit;
}

/* the data connector logo appearing at the top of some pages */
.main-logo-image {
    max-width: 5vw;
    margin-bottom: -1vw; /* the dataconnector logo has a little blank space at the bottom */
    min-width: 80px; /* this makes it so that the logo image doesnt get super cute and tiny on small screens */
    margin-top: 3vh;
}

/* the data connector logo on nav bar */
.nav-logo-img {
	width: 40px;
	height: auto;
}

.menu-icon {
    display: none;
    visibility: hidden;
}

@media only screen and (max-width: 900px) { 
    .nav-logo-img {
        display: none;
    }
    .menu-icon {
        display: block;
        font-size: large;
        color: var(--neutral-lightest);
        visibility: visible;
    }
    .img-lg {
        max-width: 50%;
    }
    .main-logo-image {
        margin-top: 0;
    }
}

.img-md {
    object-fit: contain;
    display: inline-flex;
    width: 15%;
    border-radius: 5%;
}

.img-lg {
    object-fit: contain;
    display: inline-flex;
    width: 80%;
    border-radius: 5%;
}



/* ELEMENT ORGANIZATION */

.footer {
    margin-top: 1rem;
    margin-bottom: 0px;
    margin-right: 1em;
    padding-right: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.placeholder {
    display: flex;
    width: 90%;
    height: 90%;
    padding: -10%;
    border-radius: 25px;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.input-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
}


.col {
    display: flex;   
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1; /*this makes it so that the icons on the home page stay in nice rows*/
}

.col-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.margin-10 {
    margin: 10vh;
}

.margin-5 {
    margin: 5vh;
}

.margin-3 {
    margin: 3vh;
}

.margin-1 {
    margin: 1vh;
}

.margin--3 {
    margin: -3vh;
}

.padding-3 {
    padding: 3vh;
}

.bottom {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
}

.top {
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
}

.not-sides {
    margin-right: 0;
    margin-left: 0;
}

.start {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
}

/* .login {
    margin: 5% auto 0 auto;
    max-width: 30em;
    border-radius: 10px;
    padding: 20px 30px;
} */

.box-container {
    padding: 10px 15px;
    background-color: var(--neutral-light);
}

.main-container {
    flex-direction: column;
    align-items: center;
    padding: 0;
    justify-content: center;
    margin: 0vw 2vw;
}

@media only screen and (max-width: 900px) {
    .main-container {
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 2vh 5vw;
    }
    .col-container {
        flex-direction: column;
        align-items: center;
        padding: 0;
        justify-content: center;
    }
}

@media (min-width: 1100px) {
    .main-container{
        max-width: 1050px;
        align-items: center;
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;
    }
}

/* @media (min-width: 900px) {
    .main-container{
        max-width: 850px;
        align-items: center;
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;
    }
} */

/* TEXT */

.text {
    text-align: center;
    font-family: var(--main-font);
    color: var(--secondary-color)
}

.bold {
    font-weight: bold;
}

.dramatic-title {
    font-size: 2.5em;
    font-weight: 700;
    color: var(--secondary-color);
}

.title {
    font-size: 1.5em;
    font-weight: 700;
    color: var(--neutral-dark);
}

.subtitle {
    font-size: 1.25em;
    font-weight: 300;
    margin-bottom: 1em;
}

.subheader {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 300;
    font-size: 1.25rem;
}

.subheader-detail {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 300;
    font-size: 1.0rem;
    font-style: italic;
}

.body {
    font-size: 14px;
} 

.body-title {
    color: var(--secondary-color)
}

.link-txt {
    color: var(--primary-color);
    /* font-weight: 500; */
}

.link-txt:hover {
    color: var(--secondary-color);
}

