@font-face {
    font-family: 'quicksand-vf';
    src: url('../assets/fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* DEFINING COMMON VARIABLES */
:root {
    --light-blue: #00bfff;
    --data-con-blue: #56b2e3;
    --light-blue_hover: #1188af;
    --data-con-blue: #56b2e3;
    --white_hover: #F2F2F2;
    --orange: #f89925;
    --orange_hover: #df7e07;
    --light-grey: #ced4da;
    --light-grey_hover: #a8adb3;
    --dark-grey: #555;
    --main-font: 'quicksand-vf', sans-serif;
    --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* *{
    color: #444444;
} */

body {
    color: #444444;
}

a {
    color: #444444;
}

.MuiDataGrid-columnsContainer {
    color: #444444;
}

.data-Grid-wrap.user-table .MuiDataGrid-columnsContainer,
.data-Grid-wrap.user-table .MuiDataGrid-columnsContainer .MuiButtonBase-root.MuiIconButton-root {
    color: #fff !important;
    text-transform: uppercase;
}

html,
body,
#root {
    height: 100%;
}

/* * {
    margin: 0px;
    font-family: "Quicksand";
} */
body {
    margin: 0;
    font-family: var(--main-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    text-align: center;
}

.input-form {
    /* display: flex; */
    border: 1px solid var(--light-grey);
    font-weight: 400;
    border-radius: 5px;
    height: 3vh;
    padding: .375rem .75rem;
    background-color: white;
    font-family: var(--main-font);
    width: 90%;
}

.text {
    text-align: center;
    font-family: var(--main-font);
}

.regular-font {
    font-size: 14px !important;
}

.autocomplete-input label,
.autocomplete-input div input {
    font-size: 13px;
}

/* .autocomplete-input-slim div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
*/

.error-row {
    background-color: rgba(255, 19, 19, 0.253);
}

.list-option {
    padding: 5px 10px;
}

.list-option:hover {
    background-color: #fafafa;
    cursor: pointer;
}

.list-text-box {
    width: 90% !important;
    min-width: 200px;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
}

.autocomplete-textbox {
    max-width: 400px !important;
    background-color: #fff !important;
}

.pipelines-table-header {
    color: #fff;
    font-size: 14px;
}

.table-header {
    background-color: #00bfff;
    color: #fff;
    font-size: 14px;
}

.table-header tr th {
    color: #fff;
    font-size: 14px;
}

.connector-name {
    color: #fff;
    padding: 0 10px;
}

.map-header {
    background-color: var(--data-con-blue);
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 22px;
}

.search {
    margin-left: auto;
    border: 1px solid var(--neutral-med);
    border-radius: 5px;
    outline: 0;
    padding: .375rem .75rem;
    /* margin-right: -78px; For some reason, the search bar refuses to render at the end so this is our make-do */
}

.search:hover,
.search:focus {
    border: 1px solid #000;
}

.add-button {
    background-color: var(--primary-color);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    padding: .375rem .75rem;
    border: 1px solid var(--primary-color);
    width: 100px;
    margin-right: 10px;
}

.pipelines-table-container {
    height: 70vh;
    width: 100%;
}

.table-container {
    min-height: 200px;
    width: 100%;
}

.pipelines-table-row-failed {
    background-color: rgb(255, 190, 190) !important;
}

.disabled-link {
    pointer-events: none;
}

.settings-page {
    display: flex;
    height: calc(100% - 54px);
}

.settings-component {
    width: 100%;
}

.nav-account {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account-position {
    display: "flex";
    justify-content: "center";
    align-items: "center";
}

.accounts-header {
    text-align: center;
    font-size: 2.5rem;
}

.billing-section-header {
    text-align: center;
    padding: 30px;
    font-size: 30px;
}

.billing-section-title {
    font-size: 20px;
}

.user-count {
    display: flex;
}

.user-action-buttons button {
    margin: 0 5px;
}

.account-settings {
    margin: 20px 50px;
}

.account-fgroup {
    display: flex;
    align-items: center;
    margin: 30px 0;
}

.p-20 {
    padding: 20px;
}

.account-flabel {
    text-align: left;
    margin-right: 30px;
    opacity: .8;
}



.float-right {
    float: right;
}

.account-ftext {
    flex: 1;
}

.account-ftext div {
    outline: none !important;
    border-radius: 0;
}

.auto-complete-new-table {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.auto-complete-options {
    max-height: 300px;
    width: 500px;
    overflow: auto;
    margin-bottom: 2;
}

.connection-container {
    display: flex;
    padding: 10px;
    background-color: #ccc;
    margin: 5px;
}

.connection-details {
    flex: 1;
}

.connection-header {
    display: flex;
    justify-content: space-between;
}

.connector {
    margin: 5px 5px;
}

.connector img {
    width: 150px;
    border-radius: 8px;
}

.connector img:hover {
    opacity: .3;
    cursor: pointer;
}

.greyed {
    opacity: .3;
}

.test-class {
    border: 1px solid red;
    position: relative;
}

.test-class::after {
    content: "0jhgjkgjkgjgjfdfhgdfuyyuyfuy";
    color: red;
    top: 0;
    position: relative;
}

.step-link {
    color: unset;
    text-decoration: none;
}

.step-link:hover {
    color: #00bfff;
    text-decoration: underline;
    cursor: pointer;
}

.image-footer {
    width: 75px;
    margin-right: 20px;
}

.flex-end {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px;
}

.ph {
    flex: 1;
}

.connectorList {
    display: flex;
    margin: 0px 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.field {
    margin: 5px 0px !important;
    margin: 1vh;
    font-size: 14px;
}

.bar {
    background-color: var(--data-con-blue);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    color: #fff;
    font-weight: bold;
    padding: 0px 20px;
}

.connector-list {
    display: inline-block;
}

.connector-area h3 {
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    font-weight: 500;
    opacity: .8;
}

.pipe-line-run-area h3 {
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    font-weight: 500;
    opacity: .8;
}


.currency {
    width: 20px;
    display: flex;
    justify-content: flex-end;
    float: right;
}

.amount {
    width: 90px;
    display: flex;
    justify-content: flex-end;
    float: right;
    white-space: nowrap;
}


.connector-list-item-details-list-item {
    margin-left: 30px;
    width: calc(100% - 30px);
    display: inline-block;
}

.connector-item-title,
.pipe-line-run-item-title,
.connector-total-title,
.pipe-line-run-total-title,
.total-charges {
    float: left;
}

.connector-item-title {
    padding-left: 10px;
}

.date-area {
    display: flex;
    justify-content: left;
    align-items: center;
}

.billing-flabel {
    text-align: left;
    margin-right: 5px;
    width: max-content;
    opacity: .8;
}

.connector-list-item {
    display: inline-block;
    width: 100%;
}

.connector-total,
.pipe-line-run-total,
.all-charges,
.pipe-line-run-list-item-block {
    display: inline-block;
    align-items: center;
    width: 100%;
}

.connector-list-item-header {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

.expand {
    height: 22px;
    width: 5px;
    position: relative;
    float: left;
}

.expand.expanded::after {
    transform: rotate(180deg);
}


.expand::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    transform: rotate(90deg);
    top: 8px;
}

.billing-print-btn {
    color: #444444 !important;
    border: 1px solid !important;
    border-radius: 0 !important;
    padding: 5px 10px !important;
    text-transform: unset !important;
    background-color: #E5E5E5 !important;
}

.csv-Download-btn {
    color: #444444 !important;
    border: 1px solid !important;
    border-radius: 0 !important;
    padding: 5px 10px !important;
    text-transform: unset !important;
    background-color: #E5E5E5 !important;
}

.billing-print-btn svg,
.csv-Download-btn svg {
    padding-right: 10px;
}

.total-panel-font {
    font-size: 22px;
}

.cancel-membership-btn {
    width: 165px;
    color: #444444;
    border-radius: 2px;
    padding: 5px 10px;
    text-transform: unset;
    background-color: #E5E5E5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 1.9em;
    font-size: 12px;
}

.billing-section-card-btn-area {
    margin-top: 30px;
    display: flex;
    justify-content: left;
}

.card-info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    min-width: 380px;
}

.card-info-wrap {
    padding-left: 120px;
}

.add-card-btn {
    border: 2px solid #00acee;
    border-radius: 5px;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00acee;
    font-size: 18px;
    cursor: pointer;
}

.card-info span {
    padding-left: 5px;
}

.card-edit-btn {
    margin-left: 10px;
    cursor: pointer;
    color: #00acee;
}

hr.billing-section-hr {
    border-top: 1px solid #ECECEC;
}

.alert-desc {
    font-size: 22px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.card-preferred-btn {
    border: 1px solid;
    font-size: 18px;
    line-height: 100%;
    padding: 0 4px;
}

.card-preferred-btn-wrap {
    margin-left: auto;

}

.add-or-edit-card-modal-btn-save {
    width: calc((100% - 50px)/2);
    color: #fff;
    background-color: #00acee;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
}

.add-or-edit-card-modal-btn-cancel {
    width: calc((100% - 50px)/2);
    color: #fff;
    background-color: #444444;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
}

.lock-open-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.lock-open-icon-wrap svg {
    fill: #00acee;
}

.organization-header {
    text-align: center;
    padding: 30px;
    font-size: 30px;
}

.organization-flabel {
    text-align: left;
    margin-right: 30px;
    width: 150px;
    opacity: .8;
    padding-left: 14px;
    padding-bottom: 5px;
}

.organization-ftext {
    flex: 1;
}

.organization-ftext div {
    outline: none !important;
    border-radius: 0;
}

.data-Grid-wrap {
    width: 100%;
}

.data-Grid-wrap .MuiDataGrid-root {
    border: 1px solid rgba(224, 224, 224, 1) !important;
}

.data-Grid-wrap .MuiDataGrid-row--editing .MuiDataGrid-cell--editing input {
    border-color: rgb(189, 189, 189);
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    padding: 0 15px;
    margin: 1px;
}

.data-Grid-wrap .MuiDataGrid-row--editing .MuiDataGrid-cell--editing .Mui-error input {
    background-color: rgb(126, 10, 15, 0.2);
    color: #F9564F;
    border-color: #F9564F
}

.remove-user-btn {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
}

.remove-user-btn svg {
    fill: #F9564F;
    height: 19px !important;

}

.password-reset-btn {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    color: #F9564F;
    cursor: pointer;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader[data-field="RemoveUser"] .MuiDataGrid-columnSeparator {
    opacity: 0 !important;
}

.add-user-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--data-con-blue);
    width: 160px;
    color: #ffffff;
    border-radius: 2px;
    cursor: pointer;
    height: 40px;
}

.add-user-modal {
    display: inline-block;
    position: fixed;
    top: 230px;
    width: min(850px, calc(100% - 260px));
    left: calc(200px + calc((100% - 200px)/2));
    transform: translateX(-50%);
    background-color: #ffff;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 10px;
    box-sizing: border-box;
}

.add-user-btn-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}


.select-permission>div {
    padding: 8.5px 16px;
}

.add-user-modal .add-user-save-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    margin-bottom: 20px;
    float: right;
    margin-right: 20px;
    height: 40px;
    cursor: pointer;
}

.add-user-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.add-user-modal-header-title {
    font-size: 20px;
    font-weight: 600;
    opacity: .8;
}

.add-user-modal-field-error {
    color: #F9564F;
    margin: 2px 0;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator {
    opacity: .2 !important;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
    overflow: visible !important;
}

.account-setting-page-error {
    color: #df7e07;
    margin: 2px 0;
}

.user-flabel {
    text-align: left;
    margin-right: 30px;
    width: 150px;
    opacity: .8;
}

.card-ftext div {
    outline: none !important;
}

.user-table .MuiDataGrid-cell--editing[data-field="FirstNM"] .MuiInputBase-root.Mui-error {
    position: relative;
}

.user-table .MuiDataGrid-cell--editing[data-field="FirstNM"] .MuiInputBase-root.Mui-error::after {
    position: absolute;
    content: 'First Name is required';
    color: #F9564F;
    font-size: 12px;
    bottom: 0;
    left: 15px;
}

.user-table .MuiDataGrid-cell--editing[data-field="LastNM"] .MuiInputBase-root.Mui-error {
    position: relative;
}

.user-table .MuiDataGrid-cell--editing[data-field="LastNM"] .MuiInputBase-root.Mui-error::after {
    position: absolute;
    content: 'Last Name is required';
    color: #F9564F;
    font-size: 12px;
    bottom: 0;
    left: 15px;
}

.user-table .MuiDataGrid-cell--editing[data-field="EmailTXT"] .MuiInputBase-root.Mui-error {
    position: relative;
}

.user-table .MuiDataGrid-cell--editing[data-field="EmailTXT"] .MuiInputBase-root.Mui-error::after {
    position: absolute;
    content: 'Invalid Email';
    color: #F9564F;
    font-size: 12px;
    bottom: 0;
    left: 15px;
}


.demo-card-icon-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.demo-card-icon-list-item {
    height: 20px;
    border-radius: 2px;
    cursor: pointer;
}

.demo-card-icon-list-item.selected {
    border: 2px solid #00bfff;
}

.secure-server {
    display: flex;
    justify-content: right;
    align-items: center;
}

.secure-server>svg {
    fill: #F9564F;
}

.billing-custom-credit-card {
    width: 36px;
    height: auto;
}

.user-ftext {
    outline: none !important;
}

.select-list-header {
    display: flex;
    justify-content: left;
    padding: 8.5px 14px;
    min-width: 360px;
}

.select-list-header-item:first-child {
    width: 120px;
}

.select-group-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.select-group-title::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #df7e07;
}

.select-group-title span {
    background: #ffffff;
    position: relative;
    z-index: 1;
    padding: 10px;
    color: #E5E5E5;
}

.permission-list-item {
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    width: 100%;
}

.permission-list-item-name {
    width: 120px;
    font-size: 13px;
}

.permission-list-item-description {
    font-size: 13px;
}

.permission-list-item-check-box {
    position: absolute;
    right: 0;
    top: 50%;
    width: 10px;
    transform: translateY(-50%);
}

.permission-list-item-check-box>svg {
    fill: #00bfff;
}

.organization-field-error {
    color: #F9564F;
    margin: 2px 0;
    padding-left: 14px;
}

.mail-status-icon {
    display: flex;
    justify-content: left;
    align-items: center;
}

.user-list-name-area {
    display: flex;
    justify-content: space-around;
}

.common-loader-wrap {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .6);
}

.common-loader-wrap .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    min-width: 70px;
    min-height: 70px;
    border-radius: 15px !important;

}

.common-loader-wrap .MuiCircularProgress-circle {
    stroke: #00b9ff;
}

.user-search-input {
    position: absolute;
    right: 0;
    top: -10px;
    transform: translateY(-100%);
}



.row-input input {
    padding: 5px 5px 5px 14px;
}

.map-content .select-dropdown>div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.map-content .select-dropdown>div:first-of-type {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.row-dropdown input {
    padding: 0 !important;
    font-size: 14px;
}

.row-dropdown>div:first-of-type {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.table-row td {
    padding: 5px 2px;
}

.column-autocomplete .MuiAutocomplete-inputRoot {
    padding: 0;
}

.autocomplete-input.autocomplete-input-slim .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
    padding-top: 0;
    padding-bottom: 0;
}

.autocomplete-input.autocomplete-input-slim .MuiButtonBase-root.MuiAutocomplete-clearIndicator,
.autocomplete-input.autocomplete-input-slim .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
    padding: 0;
}

.autocomplete-input.autocomplete-input-slim .MuiButtonBase-root.MuiAutocomplete-popupIndicator svg {
    height: 20px;
    width: 20px;
}

.error-log {
    background-color: rgb(255, 202, 202);
}

.MuiTableCell-root.table-header-cell {
    color: var(--data-con-blue);
    font-size: 16px;
    font-weight: 600;
}

.MuiTableCell-root.MuiTableCell-sizeSmall {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    overflow: hidden;
}



.chooseConnectorImages {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.holder-container {
    margin: 0 20px;
}

.chooseConnectorImages img:hover {
    opacity: .5;
    cursor: pointer;
}

.account-dropdown {
    display: flex;
    align-items: center;
    position: relative;
}

.connection-status-left {
    position: absolute;
    left: -25px;
}

.connection-status-right {
    position: absolute;
    right: -25px;
}

.border-highlight {
    border: 3px dashed #fff;
    border-radius: 13px;
    line-height: 12px;
    margin-bottom: 5px;
}

.border-highlight-active {
    border-color: #00bfff;
}

.horizontal-timeline {
    position: relative;
    color: var(--light-grey_hover);
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    max-width: 630px;
    width: 100%;
}

.horizontal-timeline::before,
.horizontal-timeline::after {
    position: absolute;
    content: '';
    width: 35%;
    height: 1.5px;
    background-color: var(--light-grey_hover);
    top: 8px;
}

.horizontal-timeline::before {
    left: 0;
}

.horizontal-timeline::after {
    right: 0;
}

.help-button {
    position: fixed;
    top: 50%;
    background-color: var(--data-con-blue);
    color: #fff;
    border-radius: 5px 5px 0 0;
    text-transform: uppercase;
    cursor: pointer;
    transform: rotate(-90deg);
    z-index: 100;
}

.modal {
    margin: 10% auto;
    max-width: 40em;
    padding: 20px;
}

.modalHeader {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    text-align: center;
}

.or {
    position: relative;
    border-top: 1px solid #ccc;
    margin: 20px;
}

.or span {
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    background-color: #fff;
    padding: 0px 10px;
}

.accounts {
    list-style-type: none;
    padding: 0px;
    max-height: 150px;
    overflow: auto;
}

.accounts li {
    padding: 10px;
    cursor: pointer;
}

.accounts li:hover {
    background-color: rgb(238, 238, 238);
}

.listHeader {
    font-size: 1.25rem;
    font-weight: bold;
}

.listSubheader {
    color: #737373;
}

.cm-gutterElement {
    color: var(--primary-color) !important;
    font-family: "quicksand-vf",sans-serif;
}
.cm-content {
    font-family: "quicksand-vf",sans-serif;
}

.border-pulse {
	border-radius: 50%;
	height: 10px;
	width: 10px;
	box-shadow: 0 0 0 0 rgba(86, 178, 227, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(86, 178, 227, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(86, 178, 227, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(86, 178, 227, 0);
	}
}