/* DEFINING COMMON VARIABLES */
:root {
    --primary-color: #00bfff;
    --secondary-color: #0b4f66;
    --neutral-lightest: #fff;
    --neutral-lightest_hover: #F2F2F2;
    --accent-color: #f89925;
    --accent-color_hover: #df7e07;
    --neutral-light: rgb(223, 246, 255);
    --neutral-med: rgb(138, 153, 170);
    --neutral-dark: #555;

    --main-font: 'quicksand-vf', sans-serif;

    --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    --header-height: 35px;
}

.close {
    display: flex;
    font-size: 14px;
    color: var(--neutral-med);
    align-items: top;
    justify-content: flex-end;
    margin-right: 4px;
    cursor: pointer;
}

.table-header {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    min-height: var(--header-height);
    /* margin-bottom: 20px; */
}

.MuiDataGrid-root .pipelines-table-header .MuiDataGrid-columnSeparator {
    opacity: 1 !important;
}

.logo-container {
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    margin: 5px;
    align-items: center;
}

.grid {
    width: 80%;
}

.image-no-hover, .image-no-hover:hover {
    height: 40px;
    width: auto;
    border-radius: 5px;
}

.centered, .table-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.margin-1 {
    margin: 1vh;
}

.margin-2 {
    margin: 2rem;
}

.header-margin {
    margin-top: 4px;
    margin-bottom: 2px;
    margin-right: -4px;
    margin-left: 4px;
}

.top-row {
    margin: 8px;
}

.icon {
    color: var(--neutral-med);
}

.run_result_success {
    color: green;
    font-weight: 700;
}

.run_result_fail {
    color: red;
    font-weight: 700;
}
.pipeline-running {
    color: var(--primary-color);
    text-decoration: underline;
}

.table-txt {
    font-size: 12px;
}

.header-txt {
    font-size: 12px;
    color: var(--neutral-lightest);
}

.link-txt {
    color: var(--secondary-color);
    text-decoration: underline;
}

.link-txt:hover {
    color: var(--primary-color);
    cursor: pointer;
}



.grid-row:nth-child(even) {
    background-color: var(--neutral-light);
}
.grid-row:nth-child(odd) {
    margin-top: -1px;
    margin-bottom: 0px;
}

