/* DEFINING COMMON VARIABLES */
:root {
    --light-blue: #00bfff;
    --data-con-blue: #56b2e3;
    --light-blue_hover: #1188af;
    --data-con-blue: #56b2e3;
    --white_hover: #F2F2F2;
    --orange: #f89925;
    --orange_hover: #df7e07;
    --light-grey: #ced4da;
    --light-grey_hover: #a8adb3;
    --dark-grey: #555;
    --main-font: 'quicksand-vf', sans-serif;
    --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.MuiStepIcon-root.Mui-completed {
    color: #4bb543 !important;
}

.MuiDataGrid-row:nth-child(even){
    background-color: rgb(223, 246, 255);
}

.MuiDataGrid-root {
    border-radius: 0px !important;
    border: none !important;
}

.MuiAutocomplete-groupLabel {
    text-align: center;
    margin: 0 !important;
    padding: 5px !important;
    line-height: 25px !important;
}
.MuiDataGrid-columnsContainer {
    background-color: var(--data-con-blue);
}