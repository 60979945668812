.react-datepicker__input-container input {
  padding: 8px 14px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--main-font);
}
.react-datepicker {
  font-family: var(--main-font);
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  line-height: 2rem;
  width: 6rem;
  font-size: 18px;
  border-radius: 18px;
}
.react-datepicker__triangle {
  transform: none !important;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  left: 20px;
}